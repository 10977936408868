
/*
 * Try to avoid adding any library/framework dependencies to this file.
 * It should probably remain lean and self-sufficient.
 */

if (typeof CINC == "undefined")
    CINC = {};
if (typeof CINC.data == "undefined")
    CINC.data = [];


CINC.LoadImage = function (args) {
    try {
        var height200Ratio = 200 / args.height,
        minWidth = Math.round(args.width * height200Ratio);
        document.getElementById(args.imageInstanceId).style.minWidth = minWidth + 'px';
        document.getElementById(args.imageInstanceId + '-wrapper').className += " loaded";
    }
    catch (err) {
        console.log('CINC.Preload.LoadImage - ' + JSON.stringify(args));
    }
};

CINC.HandleImageError = function (args) {
    try {
        var imgWrapper = document.getElementById(args.imageInstanceId + '-wrapper');
        imgWrapper.className += " load-error";
    }
    catch (err) {
        console.log('CINC.Preload.HandleImageError - ' + JSON.stringify(args));
    }
};

CINC.GetImgSize = function (args) {
    var imgArgs = {},
        newImg = new Image();
    imgArgs.id = args.id,
    imgArgs.pdid = args.pdid,
    imgArgs.url = CINC.data[args.pdid].gallery[args.id].url,
    imgArgs.prev = CINC.data[args.pdid].gallery[args.id].prev,
    imgArgs.next = CINC.data[args.pdid].gallery[args.id].next,
    imgArgs.imageInstanceId = args.imageInstanceId;

    newImg.addEventListener("load", function () {
        imgArgs.height = newImg.height;
        imgArgs.width = newImg.width;
        CINC.LoadImage(imgArgs);
    });
    newImg.addEventListener("error", function (e) {
        imgArgs.e = e;
        CINC.HandleImageError(imgArgs);
    });
    newImg.src = imgArgs.url; // this must be done AFTER setting events
};